import React from 'react';
import styled from '@emotion/styled';

const ModalSection = styled.section<{color: string}>` 
    background-color: ${({color}) => color};
`
const ModalWrapper = styled.div<{topPos: number}>` 
    top: ${({topPos}) => topPos}px;
`
const ModalHeader = styled.header<{color: string}>` 
    background-color: ${({color}) => color};
`

class Modal extends React.Component<any, any> {

    constructor(props: any) {
        super(props);

        this.state = {
            willDimiss: false,
            data: {},
            textAreaTop: 0
        }

        this.dismiss = this.dismiss.bind(this);
        this.didDismiss = this.didDismiss.bind(this);
        this.didAppear = this.didAppear.bind(this);
    }

    private headerRef = React.createRef<HTMLDivElement>();
    
    dismiss() {
        this.props.willDismiss();
        this.setState({ willDimiss: true });
    }

    didDismiss = () => {
        this.props.didDismiss();
    }

    didAppear = () => { }

    componentDidMount() {
        if (this.headerRef.current) {
            const headerHeight = this.headerRef.current.clientHeight;
            this.setState({ textAreaTop: headerHeight })
        }
    }

    render() {
        const { willDimiss } = this.state;
        const id = this.props.data.id + "_detail";

        return ([
            <ModalSection 
            key={`${id}_key`} 
            id={id}
            color={this.props.data.priColor}
            className={`overlay ${willDimiss ? "out " : "in"}`}
            onAnimationEnd={willDimiss ? this.didDismiss : this.didAppear}>
                <ModalHeader 
                ref={this.headerRef} 
                color={this.props.data.secColor}>
                    <div className="close_btn" onClick={this.dismiss}></div>
                    <h2 className="uppercase">{this.props.data.title}</h2>
                </ModalHeader>
                <ModalWrapper 
                    topPos={this.state.textAreaTop}
                    className={"wrapper"}>
                    <article >
                        <p>{this.props.data.details}</p>
                        <img className="figure" src={`./svg/${this.props.data.detailFigure}`} alt={this.props.data.title} />
                        <div className="read_more">
                            {this.props.linkCopy.readMore}
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href={`${this.props.linkCopy.url}`}>{this.props.linkCopy.title}</a>
                            </div>
                        </div>
                    </article>
                </ModalWrapper>
            </ModalSection>
        ]);
    }
}

export default Modal;
