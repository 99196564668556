import React from 'react';

import CloseBtn from '../graphics/close_button.svg';
import Modal from './Modal';
import Reflection from '../Reflection';
import ReflectionFigure from './RefelctionFigure';
import Reflections from '../Reflections';
import ThankYouView from './ThankYouView';

import reflectionJSON from '../json/reflection_copy_en.json';
import siteCopy from '../json/site_copy_en.json';

class Main extends React.Component <any, any>{

    constructor(props: any) {
        super(props)

        this.state = {
            isModalVisible: false,
            willDismiss: false,
            showExperienceBuddy: false,
            modalData: {}
        }

        this.showModal = this.showModal.bind(this);
        this.dismissModal = this.dismissModal.bind(this);
        this.willDismissModal = this.willDismissModal.bind(this);
        this.showExperienceBuddy = this.showExperienceBuddy.bind(this);
    }

    getURLPrameters() {
        // https://experiencebuddy.legofoundation.com/#socially=100&meaningful=100&iterative=50&actively=30&joyful=100

        const hashString = window.location.hash.toLowerCase();

        function getHashValue(key: string) {
            var matches = hashString.match(new RegExp(key+'=([^&]*)'));
            return matches ? matches[1] : null;
        }

        const arr: Array<Reflection> = [];
        var totalScore: number = 0;

        for(let item of reflectionJSON) {

            const value = getHashValue(item.urlParamaterKey);
            var score: number = 0;
    
            if (value) {
                const valueAsNumber = parseInt(value)
                score = isNaN(valueAsNumber) ? 0 : valueAsNumber;
            }
        
            totalScore += score;

            const reflection: Reflection = { 
                id: item.id, 
                title: item.title, 
                details: item.details, 
                detailFigure: 
                item.detailFigure, 
                priColor: item.priColor, 
                secColor: item.secColor,
                score: score};

            arr.push(reflection);   
            
        }
        const reflections: Reflections = { reflections: arr, totalScore: totalScore }
        return reflections
    }

    reflections: Reflections = this.getURLPrameters();
    body = document.body;

    showModal = (data: any) =>{
        this.body.classList.add('noscroll');
        this.setState({modalData: data});
        this.setState({isModalVisible: true});
        this.setState({willDismiss: false});
    }

    dismissModal = () => {
        this.setState({isModalVisible: false});
    }

    willDismissModal = () => {
        this.body.classList.remove('noscroll');
        this.setState({willDismiss: true});
    }

    showExperienceBuddy = () => {
        this.setState({showExperienceBuddy: true});
    }
   
    componentDidMount(){
        for (let index in this.reflections.reflections) {
            const preload = new Image();
            preload.src = "/svg/" + this.reflections.reflections[index].detailFigure;       
        }

        const preloadBtn = new Image();
        preloadBtn.src = CloseBtn;       
    }

    render() {
        const { isModalVisible, willDismiss, modalData , showExperienceBuddy} = this.state;
        const reflections: Reflections = this.reflections;

        return ([
            <React.Fragment key="main_key">
            <section id="main" className={`${isModalVisible && !willDismiss ? 'out' : 'in'}`}>
                    { showExperienceBuddy ? 
                    <div id="reflectionView">
                        <ReflectionFigure data={reflections} showModal={this.showModal} siteCopy={siteCopy}/>
                        <div className="info_text"> 
                            <p>{siteCopy.reflectionFigureInfo}</p><p>{siteCopy.learnMore}</p>
                        </div>
                    </div> : 
                    <ThankYouView didTapButton={this.showExperienceBuddy}/> }
                </section>
                {isModalVisible ? <Modal open={isModalVisible} data={modalData} linkCopy={siteCopy.link} didDismiss={this.dismissModal} willDismiss={this.willDismissModal}/> : null }
            </React.Fragment>
        ]);
    }
}

export default Main;
