import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react';

const myCache = createCache({
  key: 'experiencebuddy',
  nonce: '9Wj9zc8WZpZL7bF1'
})

ReactDOM.render(
  <React.StrictMode>
    <CacheProvider value={myCache}> 
      <App />
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
