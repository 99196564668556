import React from 'react';
import Button from './Button';
import Reflection from '../Reflection';
import Face from './Face';
import styled from '@emotion/styled';

const ReflectionFigureBase = styled.div<{height: string}>` 
    height: ${({height}) => height}px;
`
const ReflectionFigureHeader = styled.header<{height: string, color: string, delay: number }>` 
    height: ${({height}) => height}px;
    background-color: ${({color}) => color};
    animation-delay: ${({delay}) => delay}s !important;
`

class ReflectionFigure extends React.PureComponent<any, any> {

    private figureFace = React.createRef<HTMLDivElement>();
    private innerHeight = this.getClientInnerHeight();
    private figureHeight = (this.innerHeight / 100) * 70;
    private defaultFaceHeight: number = 60;

    constructor(props: any) {
        super(props);

        this.state = {
            figureHeight: 0
        }

        this.showModal = this.showModal.bind(this);
        this.setButtonsHeight = this.setButtonsHeight.bind(this);
    }

    showModal = (data: any) => {
        this.props.showModal(data);
    }

    setButtonsHeight(totalElements: number, totalScore: number, reflection: Reflection, figureHight: number, faceHeight: number) {
        const minButtonHeight: number = 40;
        const _totalScore = totalScore === 0 ? 100 : totalScore
        const elementScore = totalScore === 0 ? _totalScore / totalElements : reflection.score;
        const totalButtonsMinHeight = totalElements * minButtonHeight;
        const dynamicHeight = figureHight - (totalButtonsMinHeight + faceHeight);
        const ratio = dynamicHeight / _totalScore;
        const elementSize = elementScore * ratio;
        const newElementHeight = elementSize + minButtonHeight;
        return newElementHeight < minButtonHeight ? minButtonHeight : newElementHeight;
    }

    getClientInnerHeight() {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    componentDidMount() {
        this.setState({
            figureHeight: this.figureHeight - (this.figureFace.current?.clientHeight || this.defaultFaceHeight)
        })   
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }
  
    render() {
        const { figureHeight } = this.state;
        const delayFactor: number = 0.25;
        const faceHeight = this.figureFace.current?.clientHeight || this.defaultFaceHeight

        return ([
            <React.Fragment key="reflection_figure_key">
                <ReflectionFigureHeader
                    height={this.props.height}
                    color={this.props.data.priColor}
                    delay={(this.props.data.reflections.length * delayFactor) + delayFactor}
                    id="header_text"
                    className="center_text fadeIn">
                    <h1>{this.props.siteCopy.hello}</h1> 
                </ReflectionFigureHeader>
                <ReflectionFigureBase id="reflection_figure" height={figureHeight}>
                    <div className="wrapper">
                        <span ref={this.figureFace}>
                            <Face color={this.props.data.reflections[0].priColor} />
                        </span>
                        {this.props.data.reflections.map((reflection: Reflection, index: number) => {
                            const reversedIndex = this.props.data.reflections.length - 1 - index;
                            return (
                                <Button
                                    key={`${reflection.id}button_key`}
                                    data={reflection}
                                    onclick={this.showModal}
                                    transtionDelayFactor={reversedIndex * delayFactor}
                                    height={this.setButtonsHeight(
                                        this.props.data.reflections.length,
                                        this.props.data.totalScore,
                                        reflection,
                                        figureHeight,
                                        faceHeight)} />
                            );
                        })}
                    </div>
                </ReflectionFigureBase>
                <div key="legs_key" className="legs">
                    <div className="leg left"></div>
                    <div className="leg right"></div>
                </div>
            </React.Fragment>
        ]);
    }
}

export default ReflectionFigure;