import React from 'react';
import styled from '@emotion/styled';

const ReflectionFigureFace = styled.div<{color: string}>` 
    background-color: ${({color}) => color};
`

class Face extends React.Component<any, any> {
    private eyesAnimations = ["blink", "look_down", "look_up", "look_right", "look_left"];

    constructor(props: any) {
        super(props);

        this.state = {
            eyesAnimation: "look_down_start",
            timeout: 0
        }

        this.animateEyes = this.animateEyes.bind(this);
    }

    animateEyes() { 
        clearTimeout(this.state.timeout);

        this.setState({ eyesAnimation: ""});

        this.setState({
            timeout: setTimeout(() => {
                const ranNum = Math.floor(Math.random() * Math.floor(this.eyesAnimations.length));
                this.setState({ eyesAnimation: this.eyesAnimations[ranNum] });
            }, 5000)
        })
     }
    
    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    render() {
        const { eyesAnimation } = this.state;
        return ([
            <React.Fragment key="face_key">
                <ReflectionFigureFace 
                id="reflection_figure_face"
                color={this.props.color}>
                    <div className={`${eyesAnimation} eyes`}>
                        <div id="left_eye" className="eye left" onAnimationEnd={this.animateEyes}></div>
                        <div id="right_eye" className="eye right"></div>
                    </div>
                </ReflectionFigureFace>
            </React.Fragment>
        ]);
    }
}


export default Face;