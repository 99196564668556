import styled from '@emotion/styled';
import NachoAndMossImage from '../graphics/nachoandmoss.jpg';
import siteCopy from '../json/site_copy_en.json';

interface ThankYouViewProps {
    didTapButton(): void;
}

const ThankYouViewStyled = styled.div`
    font-size: 16px;
    margin: 20px 0 40px;
    padding: 0 16px;


    & .thank-you-view-container {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; 
    }   

    & .thank-you-view-container h1  {
        font-weight: 700;
        font-size: 3.2em;
        text-align: center;
    }

    & .thank-you-view-container .link  {
        font-size: 12px;
        font-weight: normal;
        color: black;
        margin: 20px;
    }
    
    & .thank-you-view-container img {
        object-fit: cover;
        width: 80%;
        height: 80%;
        max-height: 500px;
        max-width: 500px;
    }

    & .green-button {
        background-color: #149138;
        padding: 10px 15px;
        font-weight: bold;
        text-align: center;
        border-radius: 8px;
        overflow: hidden;
        color: white;
        margin: 20px;
        font-size: 1.1em;
        cursor: pointer;
    }

    & .green-button:hover {
        opacity: 0.7;
    }
`

const ThankYouView = (props: ThankYouViewProps) => {

    return (
        <ThankYouViewStyled>
            <div className="thank-you-view">
                <div className="thank-you-view-container">
                    <h1>{siteCopy.thankYouPage.headline}</h1>
                    <img src={NachoAndMossImage}/>
                    <div className="green-button" onClick={() => { props.didTapButton() }}> {siteCopy.thankYouPage.buttonText}</div>
                    <div>
                        <a target="_blank" rel="noopener noreferrer" href={`${siteCopy.link.url}`} className="link">{siteCopy.thankYouPage.linkText}</a>
                    </div>
                </div>
            </div>
        </ThankYouViewStyled>
    )
}

export default ThankYouView;
