import './App.css';
import './normalize.css';
import Main from './components/Main'
import siteCopy from './json/site_copy_en.json';
import FoundationLogo from './graphics/foundation_logo.svg';

function App() {
    return (
      <>
        <div className="wave_top"></div>
        <Main />
        <div className="wave_bottom"></div>
        <div id="foundation-logo">
          <a target="_blank" rel="noopener noreferrer" href={`${siteCopy.link.url}`}><img className="logo" src={FoundationLogo} alt="LEGO Foundation" /></a>
        </div>
      </>
  );
}

export default App;
