import React from 'react';
import styled from '@emotion/styled'

const ReflectionFigureBodyButton = styled.div<{height: string, color: string, delay: number}>` 
    height: ${({height}) => height}px !important;
    background-color: ${({color}) => color};
    transition-delay: ${({delay}) => delay}s
`

class Button extends React.PureComponent<any>{
    onClick = (data: any) => {
        this.props.onclick(data);
    }

    render() {        
        const btnName: string = (this.props.data.id + "_btn").toLowerCase();

        return (
            <React.Fragment key="button_fragment">
                <ReflectionFigureBodyButton 
                    height={this.props.height} 
                    id={btnName}
                    color={this.props.data.priColor}
                    delay={this.props.transtionDelayFactor}
                    className={"btn pop_animation center_content"}
                    onClick={this.onClick.bind(this, this.props.data)}>
                    <p className={"negative_text uppercase"}>
                        {this.props.data.title}
                    </p>
                </ReflectionFigureBodyButton>
            </React.Fragment>
        );
    }
}

export default Button;